import React from "react";
import classNames from "classnames";
import {XMarkIcon} from "@heroicons/react/24/solid";
import axios from "axios";
import {NavLink, useLocation, useNavigate} from "react-router-dom";

import {Badge} from "../";
import {API_URL, tables, actions} from "../../api";

import "./List.scss";

const List = ({items, isRemovable, onClick, onRemove, onClickItem}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const removeList = (list) => {
        if (window.confirm('Are you sure you want to delete the list?')) {
            axios
                .post(API_URL, {
                    table: tables.lists,
                    action: actions.delete,
                    id: list.id
                })
                .then(() => {
                    if (location.pathname.split("lists/")[1] === list.id) {
                        navigate("/lists/all");
                        console.log('deleted');
                    } else {
                        console.log('not deleted');
                    }
                    onRemove(list.id);
                });
        }
    }

    return (
        <ul className="todo__lists" onClick={onClick}>
            {items.map((item) => (
                <NavLink to={item.path ?? `/lists/${item.id}`}
                         className={classNames(item.className ?? "todo__lists-item")}
                         onClick={onClickItem ? (() => onClickItem(item)) : null}
                         key={item.id ?? 0}
                >
                    <i className={classNames({"icon": item.icon}, "inline-flex items-center w-6 h-6")}>
                        {
                            item.icon
                                ? item.icon
                                : <Badge color={item.color.name}>{item.tasks.length}</Badge>
                        }
                    </i>
                    <span className="todo__lists-item__text">{item.name}</span>
                    {isRemovable && item.userId !== "0" && (
                        <i onClick={(e) => {
                            e.preventDefault();
                            removeList(item);
                        }}>
                            <XMarkIcon className={"todo__lists__remove-icon"}/>
                        </i>
                    )}
                </NavLink>
            ))}
        </ul>
    );
};

export default List;
