import React, {useContext, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

import "./Home.scss";
import {MyButton, MyInputGroup} from "../index";
import {AuthContext} from '../../AuthContext';
import {API_URL, tables, actions} from "../../api";

import logo from "../../assets/img/logo.svg";
import img1 from "../../assets/img/ScreenshotLists.png";
import img2 from "../../assets/img/ScreenshotTasks.png";
import img3 from "../../assets/img/ScreenshotAllTasks.png";
import img4 from "../../assets/img/ScreenshotThemeSwitch.mp4";
import {XCircleIcon} from "@heroicons/react/24/outline";
import axios from "axios";
import classNames from "classnames";

const Home = () => {
    const {isAuthenticated, login, logout} = useContext(AuthContext);
    const [visiblePopup, setVisiblePopup] = useState(false);
    const [inputValues, setInputValues] = useState({login: "", password: ""});
    const [inputAlert, setInputAlert] = useState("");
    const navigate = useNavigate();

    const handleLogin = () => {
        login();
    };

    const handleLogout = () => {
        logout();
    };

    const onClose = () => {
        setVisiblePopup(false);
        setInputValues({login: "", password: ""});
        setInputAlert("");
    };

    const submitHandler = (e) => {
        e.preventDefault();
        axios
            .post(API_URL, {
                table: tables.users,
                action: actions.login,
                login: inputValues.login,
                pass: inputValues.password
            })
            .then(({data}) => {
                if (data['error']) {
                    setInputAlert(data['error']);
                } else {
                    handleLogin();
                    onClose();
                    navigate('/lists/all');
                }
            })
    }

    return (
        <div className="home">
            <CustomScroll>
                <div className="home__wrapper">
                    {visiblePopup && (
                        <div className="auth-popup" onClick={onClose}>
                            <div className="auth-popup__wrapper"
                                 onClick={e => e.stopPropagation()}>
                                <i><XCircleIcon fill="white" className="close-button"
                                                onClick={onClose}/></i>
                                <form id="login-form" onSubmit={submitHandler}>
                                    <MyInputGroup>
                                        <label htmlFor="login">Login</label>
                                        <input
                                            className={classNames({"alert": inputAlert})}
                                            type="text"
                                            id="login"
                                            defaultValue={inputValues.login}
                                            onChange={e => {
                                                setInputValues({...inputValues, login: e.target.value});
                                                setInputAlert("");
                                            }}
                                            autoFocus
                                        />
                                    </MyInputGroup>
                                    <MyInputGroup>
                                        <label htmlFor="password">Password</label>
                                        <input
                                            className={classNames({"alert": inputAlert})}
                                            type="password"
                                            id="password"
                                            defaultValue={inputValues.password}
                                            onChange={e =>
                                                setInputValues({...inputValues, password: e.target.value})
                                            }
                                        />
                                        {inputAlert && <small>{inputAlert}</small>}
                                    </MyInputGroup>
                                    <MyButton type="submit">Login</MyButton>
                                </form>
                            </div>
                        </div>
                    )}
                    <header className="header">
                        <div className="container">
                            <div className="header__wrapper">
                                <div className="header__logo">
                                    <img src={logo} alt="Logo"/>
                                </div>
                                <div className="header__button">
                                    {isAuthenticated ? (
                                        <div>
                                            <MyButton
                                                onClick={() =>
                                                    handleLogout()
                                                }
                                            >Logout</MyButton>
                                        </div>
                                    ) : (
                                        <div>
                                            <MyButton
                                                onClick={() =>
                                                    setVisiblePopup(!visiblePopup)
                                                }
                                            >Login</MyButton>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </header>

                    <section className="intro">
                        <div className="container">
                            <div className="intro__wrapper">
                                <h1>Your daily life organizer</h1>
                                <h2>
                                    Stay on track, achieve success - with our
                                    ultimate todo app!
                                </h2>
                                {isAuthenticated ? (
                                    <Link to="/lists/all">
                                        <MyButton>Enter app</MyButton>
                                    </Link>
                                ) : (
                                    <div>
                                        <MyButton
                                            onClick={() =>
                                                setVisiblePopup(!visiblePopup)
                                            }
                                        >Login</MyButton>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>

                    <section className="images">
                        <div className="images__block">
                            <img src={img1} alt="Task lists"/>
                            <p>
                                You can create any number of lists on any topic
                                you want!
                                <br/>
                                Sort your tasks. Divide big goals into smaller
                                ones.
                            </p>
                        </div>

                        <div className="images__block">
                            <p>
                                Track the progress of tasks and brush aside
                                completed tasks.
                                <br/>
                                Easily add new tasks with one click.
                            </p>
                            <img src={img2} alt="Tasks"/>
                        </div>

                        <div className="images__block">
                            <img src={img3} alt="All lists"/>
                            <p>
                                View a list of all your tasks at once in one
                                window.
                                <br/>
                                Analyze your todo lists and set yourself goals.
                            </p>
                        </div>

                        <div className="images__block">
                            <p>
                                Do you work at night or just love dark colors?
                                <br/>
                                Choose Night Mode. No complex settings, just one
                                click.
                            </p>
                            <video loop autoPlay muted>
                                <source type="video/mp4" src={img4}></source>
                            </video>
                            {/*<img src={img4} alt="Theme switch" />*/}
                        </div>
                    </section>
                    <footer className="footer">
                        <div className="container">
                            <div className="footer__wrapper">
                                <div className="footer__logo">
                                    <img src={logo} alt=""/>
                                </div>
                                <div className="footer__copyright">
                                    <span>
                                        &copy; 2023 Your daily life organizer
                                    </span>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </CustomScroll>
        </div>
    );
};

export default Home;
