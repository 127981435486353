import React, { useEffect, createContext, useState } from "react";

const defaultTheme = "light";
const ThemeContext = createContext(defaultTheme);

// Function to get the theme from local storage or use the default theme
const getTheme = () => {
    const theme = localStorage.getItem("theme");
    if (!theme) {
        localStorage.setItem("theme", defaultTheme);
        return defaultTheme;
    } else {
        return theme;
    }
};

const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(getTheme);

    function toggleTheme() {
        if (theme === "dark") {
            setTheme("light");
        } else {
            setTheme("dark");
        }
    }

    // Update the local storage when the theme changes
    useEffect(() => {
        localStorage.setItem("theme", theme.toString());
    }, [theme]);

    return (
        <ThemeContext.Provider
            value={{
                theme,
                setTheme,
                toggleTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
}

export { ThemeContext, ThemeProvider };