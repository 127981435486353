import React from 'react';
import classNames from "classnames";
import "./MyInputGroup.scss";

const MyInputGroup = ({className, children}) => {
    return (
        <div className={classNames("input-group", className)}>
            {children}
        </div>
    );
};

export default MyInputGroup;