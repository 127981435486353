import React from "react";
import {Outlet} from "react-router-dom";
import {ListBulletIcon} from "@heroicons/react/24/solid";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

import {AddList, List} from "./index";
import ThemeSwitch from "./ThemeSwitch";

const Layout = ({lists, setLists, activeList, colors, onAddList}) => {
    return (
        <>
            <div className="App">
                <div className="todo">
                    <CustomScroll className="sidebar-container">
                        <aside className="todo__sidebar">
                            {/* All tasks button */}
                            <List items={[
                                {
                                    path: "/lists/all",
                                    active: true,
                                    name: "All tasks",
                                    icon: <ListBulletIcon/>
                                }
                            ]}/>

                            {/* Task lists */}
                            <List
                                isRemovable
                                onRemove={id => {
                                    const newLists = lists.filter(list => list.id !== id);
                                    setLists(newLists);
                                }}
                                items={lists}
                                activeItem={activeList}
                                className={"todo__lists-item"}
                            />

                            <AddList colors={colors} onAdd={onAddList}/>
                        </aside>

                    </CustomScroll>
                    <CustomScroll className="tasks-container flex-1">
                        <div className="todo__tasks">
                            <Outlet/>
                        </div>
                    </CustomScroll>

                    <ThemeSwitch/>
                </div>
            </div>
        </>
    );
};

export default Layout;