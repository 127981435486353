import React from 'react';
import {CheckIcon, TrashIcon} from "@heroicons/react/24/solid";
import axios from "axios";

import {API_URL, tables, actions} from "../../api";

const TaskItem = ({sharedList, sharedCompleted, task, onEditTask, onRemove, onChangeCompleted}) => {
        const editTaskText = (text) => {
            if (sharedList) return;
            if (text.trim() === "") {
                removeTask();
                return;
            }

            if (text !== task.text) {
                onEditTask(task.listId, task.id, text);
                axios
                    .post(API_URL, {
                        table: tables.tasks,
                        action: actions.edit,
                        id: task.id,
                        text
                    })
                    .catch(() => {
                        alert("Failed to edit task!");
                    });
            }
        }

        const removeTask = () => {
            if (sharedList) return;
            axios
                .post(API_URL, {
                    table: tables.tasks,
                    action: actions.delete,
                    id: task.id
                })
                .then(() => {
                    onRemove(task.listId, task.id);
                })
                .catch(() =>
                    alert("Failed to delete tasks!")
                );
        }

        const onChangeCheckbox = (e) => {
            onChangeCompleted(task.listId, task.id, e.target.checked);
            if (!sharedList) {
                axios
                    .post(API_URL, {
                        table: tables.tasks,
                        action: actions.check,
                        id: task.id,
                        completed: e.target.checked
                    })
                    .catch(() => {
                        alert("Failed to edit task!");
                    });
            }
        }

        return (
            <div className="tasks__items-row">
                <div className="checkbox">
                    <input
                        id={`task-${task.id}`}
                        type="checkbox"
                        defaultChecked={
                            sharedList
                                ? sharedCompleted.includes(task.id.toString())
                                : task.completed
                        }
                        onChange={onChangeCheckbox}
                    />
                    <label htmlFor={`task-${task.id}`}>
                        <CheckIcon className="check-icon"/>
                    </label>
                </div>
                {sharedList ?
                    (<label htmlFor={`task-${task.id}`} className="task-text">
                        {task.text}
                    </label>)
                    :
                    (<input
                        disabled={sharedList}
                        className="task-text"
                        type="text"
                        defaultValue={task.text}
                        onKeyDown={e => {
                            if (e.code === "Enter") {
                                e.preventDefault();
                                editTaskText(e.target.value);
                            }
                        }}
                        onBlur={e => {
                            editTaskText(e.target.value);
                        }}
                    />)}
                {!sharedList &&
                    <TrashIcon className="task-delete-icon" onClick={removeTask}/>
                }
            </div>
        );
    }
;

export default TaskItem;