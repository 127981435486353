import React, {useContext} from 'react';
import axios from "axios";
import TextareaAutosize from 'react-textarea-autosize';
import {ArrowLeftOnRectangleIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";

import AddTaskForm from "./AddTaskForm";
import TaskItem from "./TaskItem";

import "./Tasks.scss";
import {AuthContext} from "../../AuthContext";
import {API_URL, tables, actions} from "../../api";

const Tasks = ({sharedCompleted, lists, activeList, onEditTitle, onAddTask, ...props}) => {
    const navigate = useNavigate();
    const {logout} = useContext(AuthContext);
    const activeLists = activeList ? [activeList] : lists;
    const completedCount = activeList ? activeList.tasks.filter(task => task.completed === true).length : 0;

    const handleLogout = () => {
        logout();
        navigate('/');
    }

    const editTitle = (list, newTitle) => {
        if (newTitle !== list.name) {
            onEditTitle(list.id, newTitle);
            axios
                .post(API_URL, {
                    table: tables.lists,
                    action: actions.edit,
                    id: list.id,
                    name: newTitle
                })
                .catch(() =>
                    alert("Failed to change list name!")
                );
        }
    }

    const removeCompleted = () => {
        if (activeList) {
            props.onRemoveCompleted(activeList.id);

            axios
                .post(API_URL, {
                    table: tables.tasks,
                    action: actions.deleteCompleted,
                    listId: activeList.id
                })
                .catch(() =>
                    alert("Failed to delete tasks!")
                );
        }
    }

    return (
        <div className="tasks">
            <div className="logout-button" onClick={handleLogout}>
                <ArrowLeftOnRectangleIcon/>
            </div>
            {
                lists && lists.length === 0
                    ? (<h3>No task lists</h3>)
                    : (activeLists && activeLists.map(list =>
                        <div className="tasks__list" key={list.id}>
                            <h2 className={`tasks__title text--${list.color.name}`}>
                                <TextareaAutosize
                                    disabled={list.userId === "0"}
                                    spellCheck={false}
                                    onBlur={e => {
                                        editTitle(list, e.target.value);
                                    }}
                                    rows={1}
                                >{list.name}</TextareaAutosize>
                            </h2>

                            {completedCount > 0 && list.userId !== "0" &&
                                <div className="tasks__remove-completed">
                                    <small onClick={removeCompleted}>Remove completed</small>
                                </div>
                            }

                            <div className="tasks__items">
                                {!list.tasks.length
                                    ? <h3>No tasks</h3>
                                    : list.tasks.map((task) => (
                                        <TaskItem
                                            sharedCompleted={sharedCompleted}
                                            key={task.id}
                                            sharedList={list.userId === "0"}
                                            task={task}
                                            onEditTask={props.onEditTask}
                                            onRemove={props.onRemove}
                                            onChangeCompleted={props.onChangeCompleted}/>
                                    ))
                                }
                                {list.userId !== "0" &&
                                    <AddTaskForm key={list.id} listId={list.id} onAddTask={onAddTask}/>
                                }
                            </div>
                        </div>
                    ))}
        </div>
    );
};

export default Tasks;