import React from 'react';
import classNames from "classnames";
import "./MyButton.scss";

const MyButton = ({className, type = "button", ...props}) => {
    return (
        <button type={type}
                className={classNames("my-button", className)}
                {...props}
        >{props.children}</button>
    );
};

export default MyButton;