import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {PlusIcon, XCircleIcon} from "@heroicons/react/24/outline";
import axios from "axios";

import {List, MyButton, Badge, MyInputGroup} from "../";
import {API_URL, tables, actions} from "../../api";

import "./AddList.scss";
import {useLocation, useNavigate} from "react-router-dom";

const AddList = ({colors, onAdd}) => {
    const [selectedColor, setSelectedColor] = useState(1);
    const [inputValue, setInputValue] = useState("");
    const [visiblePopup, setVisiblePopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputAlert, setInputAlert] = useState("");
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (Array.isArray(colors)) {
            setSelectedColor(colors[0].id);
        }
    }, [colors]);

    const addList = () => {
        if (!inputValue.trim()) {
            setInputAlert("Enter list name!");
            return;
        }

        setIsLoading(true);
        axios
            .post(API_URL, {
                table: tables.lists,
                action: actions.add,
                name: inputValue.trim(),
                colorId: selectedColor
            })
            .then(({data}) => {
                const color = colors.find(color => color.id === selectedColor).name;
                const newList = {...data, color: {name: color}, tasks: []};
                onAdd(newList);
                navigate(`/lists/${newList.id}`);
            })
            .catch(() => {
                alert("List add error!");
            })
            .finally(() => {
                onClose();
                setIsLoading(false);
            });

        setSelectedColor(colors[0].id);
        setInputValue("");
    }

    const onClose = () => {
        setVisiblePopup(false);
        setInputValue('');
        setInputAlert("");
        setSelectedColor(colors[0].id);
    };

    return (
        <div className="add-list">
            <List
                onClick={() => {
                    setVisiblePopup(!visiblePopup);
                }}
                items={[
                    {
                        path: location.pathname,
                        className: "add-list-button",
                        icon: <PlusIcon/>,
                        name: "Add list"
                    }
                ]}
            />
            {visiblePopup && (
                <div className="add-list__popup">
                    <i><XCircleIcon fill="white" className="add-list__popup-close-button" onClick={onClose}/></i>

                    <ul className="add-list__popup-color-picker">
                        {colors.map((color) =>
                            <Badge
                                onClick={() => {
                                    setSelectedColor(color.id);
                                }}
                                color={color.name}
                                className={classNames({"active": selectedColor === color.id})}
                                key={color.id}
                            />
                        )}
                    </ul>

                    <MyInputGroup>
                        <input
                            autoFocus
                            className={classNames("scale", {"alert": inputAlert})}
                            type="text"
                            placeholder="Task list name"
                            value={inputValue}
                            onChange={e => {
                                setInputValue(e.target.value);
                                setInputAlert("");
                            }}
                            onKeyDown={e => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                    document.getElementById("addListBtn").click();
                                }
                            }}
                            required
                        />
                        {inputAlert && <small>{inputAlert}</small>}
                    </MyInputGroup>

                    <MyButton id="addListBtn" onClick={addList}>
                        {isLoading ? "Loading..." : "Add"}
                    </MyButton>
                </div>
            )}
        </div>
    );
};

export default AddList;
