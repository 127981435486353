export const API_URL = `https://${window.location.hostname}/api/api.php`;
export const tables = {
    lists: 'lists',
    colors: 'colors',
    tasks: 'tasks',
    users: 'users',
    taskCompletion: 'task_completion'
}
export const actions = {
    logout: 'logout',
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    check: 'check',
    deleteCompleted: 'delete_completed',
    login: 'login',
}