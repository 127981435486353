import React, {createContext, useEffect, useState} from 'react';
import axios from "axios";

import {API_URL, tables} from "./api";

// Создаем контекст авторизации
export const AuthContext = createContext();

// Создаем провайдер контекста
export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        axios
            .get(`${API_URL}?table=${tables.users}`)
            .then(({data}) => {
                if (data['user_id']) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            })
            .catch(() => {
                alert('Server error!');
            })
    }, [])

    // Функция для входа пользователя
    const login = () => {
        setIsAuthenticated(true);
    };

    // Функция для выхода пользователя
    const logout = () => {
        axios
            .get(`${API_URL}?table=${tables.users}`)
            .then(() => {
                setIsAuthenticated(false);
            })
            .catch(() => {
                alert('Server error!')
            })
    };

    // Значения контекста, доступные для дочерних компонентов
    const authContextValues = {
        isAuthenticated,
        login,
        logout,
    };

    return (
        <AuthContext.Provider value={authContextValues}>
            {children}
        </AuthContext.Provider>
    );
};
