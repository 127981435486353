import React from 'react';
import classNames from "classnames";

import "./Badge.scss";

const Badge = ({color, className, onClick, children}) => {
    return (
        <i className={classNames("badge", {[`color--${color}`]: color}, className)} onClick={onClick}>{children}</i>
    );
};

export default Badge;