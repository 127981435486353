import React from 'react';

import "./NotFound.scss";
import {MyButton} from "../index";
import {Link} from "react-router-dom";

const NotFound = () => {
    return (
        <div className="not-found">
            <div className="not-found__404">404</div>
            <div className="not-found__text">Page not found :(</div>
            <div>
                <Link to="/">
                    <MyButton>Return home</MyButton>
                </Link>
            </div>
        </div>
    );
};

export default NotFound;