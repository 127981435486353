import React, {useContext, useEffect, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import axios from "axios";
import {ArrowPathIcon} from "@heroicons/react/24/outline";

import {Tasks} from "./components";
import Layout from "./components/Layout";
import Home from "./components/Home";
import {ThemeContext} from "./ThemeContext";
import {AuthContext} from "./AuthContext";
import NotFound from "./components/NotFound";
import {API_URL, tables} from "./api";

function App() {
    const location = useLocation();
    const {isAuthenticated} = useContext(AuthContext);
    const {theme} = useContext(ThemeContext);
    const [lists, setLists] = useState(null);
    const [colors, setColors] = useState(null);
    const [sharedCompleted, setSharedCompleted] = useState(null);
    const [activeList, setActiveList] = useState(null);

    useEffect(() => {
        // Fetch the lists and colors data from the API
        axios
            .get(`${API_URL}?table=${tables.lists}`)
            .then(({data}) => {
                setLists(data);
            });
        axios.get(`${API_URL}?table=${tables.colors}`)
            .then(({data}) => {
                setColors(data);
            });
        axios
            .get(`${API_URL}?table=${tables.taskCompletion}`)
            .then(({data}) => {
                setSharedCompleted(data);
                console.log(data);
            });
    }, [isAuthenticated]);

    useEffect(() => {
        // Update the theme class on the document element
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [theme]);

    useEffect(() => {
        if (lists) {
            // Extract the active list based on the current route
            const listId = location.pathname.split("lists/")[1];
            const activeList = lists.find((list) => list.id === listId);
            setActiveList(activeList);
        }
    }, [lists, location.pathname]);

    // Event handlers
    const onAddList = (newList) => {
        setLists([...lists, newList]);
    };

    const onEditListTitle = (id, title) => {
        const newLists = lists.map((list) => {
            if (list.id === id) {
                list.name = title;
            }
            return list;
        });
        setLists(newLists);
    };

    const onAddTask = (listId, newTaskObj) => {
        const newLists = lists.map((list) => {
            if (list.id === listId) {
                list.tasks = [...list.tasks, newTaskObj];
            }
            return list;
        });
        setLists(newLists);
    };

    const onEditTask = (listId, taskId, text) => {
        const newLists = lists.map((list) => {
            if (list.id === listId) {
                list.tasks = list.tasks.map((task) => {
                    if (task.id === taskId) {
                        task = {
                            ...task,
                            text,
                        };
                    }
                    return task;
                });
            }
            return list;
        });
        setLists(newLists);
    };

    const onRemoveTask = (listId, taskId) => {
        const newLists = lists.map((list) => {
            if (list.id === listId) {
                list.tasks = list.tasks.filter((task) => task.id !== taskId);
            }
            return list;
        });
        setLists(newLists);
    };

    const onRemoveCompleted = (listId) => {
        const newLists = lists.map((list) => {
            if (list.id === listId) {
                list.tasks = list.tasks.filter(
                    (task) => task.completed === false
                );
            }
            return list;
        });
        setLists(newLists);
    };

    const onChangeCompleted = (listId, taskId, completed) => {
        const newLists = lists.map((list) => {
            if (list.id === listId) {
                list.tasks = list.tasks.map((task) => {
                    if (task.id === taskId) {
                        task = {
                            ...task,
                            completed,
                        };
                    }
                    return task;
                });
            }
            return list;
        });
        setLists(newLists);
    };

    return <>
        {lists && colors && sharedCompleted ?
            (<Routes>
                {isAuthenticated && <Route
                    path="/lists"
                    element={
                        <Layout
                            lists={lists}
                            setLists={(value) => setLists(value)}
                            activeList={activeList}
                            colors={colors}
                            onAddList={onAddList}
                        />
                    }
                >
                    <Route
                        path=":id"
                        element={
                            <Tasks
                                sharedCompleted={sharedCompleted}
                                activeList={activeList}
                                onEditTitle={onEditListTitle}
                                onAddTask={onAddTask}
                                onEditTask={onEditTask}
                                onRemove={onRemoveTask}
                                onRemoveCompleted={onRemoveCompleted}
                                onChangeCompleted={onChangeCompleted}
                            />
                        }
                    />
                    <Route
                        path="all"
                        element={
                            <Tasks
                                sharedCompleted={sharedCompleted}
                                lists={lists}
                                onEditTitle={onEditListTitle}
                                onAddTask={onAddTask}
                                onEditTask={onEditTask}
                                onRemove={onRemoveTask}
                                onChangeCompleted={onChangeCompleted}
                            />
                        }
                    />
                </Route>}
                <Route path="/" element={<Home/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>)
            :
            (<div className="loading">
                <ArrowPathIcon className="loading-icon"/>
                <div className="loading-text">Loading...</div>
            </div>)
        }
    </>
}

export default App;
